<template>
  <div>
    <div class="vx-row mb-12">
      <div class="vx-col mb-15 w-full space-y-1">
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg">PI Date</span>
          </div>
          <div class="w-full">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="filterDate"
              :linkedCalendars="true"
              @select="(val) => saveDate(val)"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ datePickerDateFormat(picker.startDate) }} -
                {{ datePickerDateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg">City</span>
          </div>
          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectCity"
              :options="optionalCity"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="Name"
              name="Name"
              @select="(val) => saveCity(val)"
              @remove="(val) => saveCity(val)"
            />
            <!-- <span v-show="errors.has('vehicle')" class="text-danger"
            >Vehicle is required</span
          > -->
          </div>
        </div>
        <div class="flex flex-row w-1/2">
          <div class="w-1/2">
            <span class="text-lg">District</span>
          </div>
          <div class="w-full">
            <multiselect
              class="selectExample"
              v-model="selectDistrict"
              :options="optionalDistrict"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              track-by="id"
              placeholder="Type to search"
              :searchable="true"
              label="name"
              name="name"
              @select="(val) => saveDistrict(val)"
              @remove="(val) => saveDistrict(val)"
            />
            <!-- <span v-show="errors.has('vehicle')" class="text-danger"
            >Vehicle is required</span
          > -->
          </div>
        </div>

        <div class="vx-col w-1/2 mt-8 flex justify-end space-x-1">
          <vs-button
            @click="onClickBtnFilter"
            size="medium"
            color="primary"
            icon-pack="feather"
            icon="icon-search"
            title="Search"
          >
            Filter</vs-button
          >
          <!-- <vs-button
            @click="onClickBtnReset"
            size="medium"
            color="warning"
            icon-pack="feather"
            icon="icon-rotate-ccw"
            title="Reset"
          >
            Reset</vs-button
          > -->
        </div>
      </div>
      <div
        v-bind:class="[
          detail ? detailShow : detailView ? detailShow : '',
          detailHide,
        ]"
      >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="10"
          :total="table.total"
          @change-page="handleChangePage"
          @search="handleSearch"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>
              <vs-checkbox v-model="selectAll" @input="handleSelectAll" />
            </vs-th>
            <vs-th> View </vs-th>
            <vs-th>Cancel</vs-th>
            <vs-th sort-key="pi_date">PI Date</vs-th>
            <vs-th sort-key="delivery_order_code">PI Code</vs-th>
            <vs-th sort-key="request_delivery_date">RDD</vs-th>
            <vs-th sort-key="customer_address_code">Ship To</vs-th>
            <vs-th sort-key="customer_name">Customer Name</vs-th>
            <vs-th sort-key="customer_address">Address</vs-th>
            <vs-th sort-key="customer_district">District</vs-th>
            <vs-th sort-key="weight">Total Weight(Kg)</vs-th>
            <vs-th sort-key="volume">Total Volume(m3)</vs-th>
            <vs-th sort-key="count_uom">Total UOM</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div class="vx-input-group flex">
                  <vs-checkbox
                    v-model="selectedItems[indextr]"
                    @input="(val) => handleSelectItems(val, tr)"
                  />
                </div>
              </vs-td>
              <vs-td>
                <vs-button
                  size="small"
                  color="warning"
                  icon-pack="feather"
                  icon="icon-eye"
                  style="margin-right: 5px"
                  title="Show"
                  @click="viewDODetail(tr.items)"
                />
              </vs-td>
              <vs-td>
                <vs-button
                  size="small"
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  style="margin-right: 5px"
                  title="Cancel"
                  @click="OpenModal(true,tr)"
                />
                </vs-td>  
              <vs-td :data="data[indextr].pi_date">
                {{ data[indextr].pi_date }}
              </vs-td>
              <vs-td :data="data[indextr].delivery_order_code">
                {{ data[indextr].delivery_order_code }}
              </vs-td>
              <vs-td :data="data[indextr].request_delivery_date">
                {{ data[indextr].request_delivery_date }}
              </vs-td>
              <vs-td :data="data[indextr].customer_address_code">
                {{ data[indextr].customer_address_code }}
              </vs-td>
              <vs-td :data="data[indextr].customer_name">
                {{ data[indextr].customer_name }}
              </vs-td>
              <vs-td :data="data[indextr].customer_address">
                {{ data[indextr].customer_address }}
              </vs-td>
              <vs-td :data="data[indextr].customer_district">
                {{ data[indextr].customer_district }}
              </vs-td>
              <vs-td :data="data[indextr].weight">
                {{ data[indextr].weight }}
              </vs-td>
              <vs-td :data="data[indextr].volume">
                {{ data[indextr].volume }}
              </vs-td>
              <vs-td :data="data[indextr].count_uom">
                {{ data[indextr].count_uom }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="!detailView">
            <vs-button class="mr-3 mb-2" v-on:click="viewDetail()"
              >Add Vehicle</vs-button
            >
            <vs-button class="mr-3 mb-2 bg-success" v-on:click="ExportForm()"
              >Export</vs-button
            >
          </div>
        </div>
      </div>
      <!--view detail ----->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShowV2 : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="right"
              vs-align="right"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <Table2
              @close="handleClose"
              :dataViews="selectedData"
              :isNew="true"
              @sendData="DataCheck($event)"
            ></Table2>
          </vs-row>
        </div>
        <div
          v-if="detailView"
          v-bind:class="[detailView ? detailShowV2 : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="right"
              vs-align="right"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <Table3
              @close="handleClose"
              :dataViews="dataViews"
              :isNew="true"
              @sendData="DataCheck($event)"
            ></Table3>
          </vs-row>
        </div>
      </transition>
      
    </div>
    <vs-popup classContent="popup-example" :active.sync="showfirstModal" title="Cancel PI" width="800" >
        <div class="vx-row justify-between p-2">
          <div class="vx-col ml-3">
            <span>PI Code</span>
          </div>
          <div class="vx-col w-3/4 ">
            <vs-input v-model="PICode" readonly />
          </div>
        </div>
        <div class="vx-row justify-between p-2">
          <div class="vx-col ml-3">
            <span>Reason</span>
          </div>
          <div class="vx-col w-3/4 ">
            <multiselect
              class="selectExample"
              v-model="selectReason"
              :options="optionReason"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              track-by="id"
              :max-height="100"
              :searchable="true"
              label="reason"
              name="name"
            />
          </div>
        </div>
        <div class="vx-row mt-5 justify-center ">
          <div class="vx-col w-full justify-end">
            <vs-button @click="saveCancelPI" color="danger" class="mr-2">Confirm</vs-button>
          </div>
        </div>
      </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import Table2 from "../table2.vue";
import Table3 from "../tableViewSimulation.vue";
import DateRangePicker from "vue2-daterange-picker";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    Table2,
    Table3,
    DateRangePicker,
  },

  data() {
    return {
      deleteId: null,
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailShowV3: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      table: this.tableDefaultState(),
      selectAll: false,
      filterDate: this.$store.state.tms.tms.date,
      selectDistrict: this.$store.state.tms.tms.district,
      optionalDistrict: [],
      selectCity: this.$store.state.tms.tms.city,
      optionalCity: [],
      detail: false,
      detailView: false,
      dataViews: [],
      districts: [],
      selectedItems: this.$store.state.tms.tms.selected,
      showfirstModal: false,
      optionReason: [],
      selectReason: null,
      PIData: null,
      PICode:null,
    };
  },
  mounted() {
    this.getData();
    this.getCity();
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [2, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      if (searching == "") {
        this.table.length = 10;
      }
      this.table.page = 1;
      this.getData();
    },
    handleSelectAll(val) {
      this.selectAll = val;
      if (val) {
        this.selectedItems = this.table.data.map(() => true);
        this.$store.commit("tms/tms/setSelected", [...this.table.data]);
      } else {
        this.selectedItems = this.table.data.map(() => false);
        this.$store.commit("tms/tms/setSelected", []);
      }
    },
    handleSelectItems(val, item) {
      const index = this.table.data.findIndex(
        (dataItem) => dataItem.id === item.id
      );
      this.selectedItems.splice(index, 1, val);

      if (val) {
        this.$store.commit("tms/tms/addSelected", item);
      } else {
        this.$store.commit("tms/tms/removeSelected", item);
      }

      // Update selectAll state if all or none are selected
      this.selectAll = this.selectedItems.every(Boolean);
    },
    viewDetail() {
      if (this.$store.state.tms.tms.selected.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      this.dataViews = [];
      this.detail = true;
      this.dataViews = this.$store.state.tms.tms.selected;
    },
    viewDODetail(items) {
      this.dataViews = [];
      this.detailView = true;
      this.detail = false;
      this.dataViews = items;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.detail = false;
      this.detailView = false;
      this.dataViews = [];
      this.getData();
    },

    getData() {
      console.log("district", this.districts);
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-transport-delivery-plan-line", {
          params: {
            page: this.table.page,
            length: this.table.length,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date_from: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
            district: this.districts,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page
              ? resp.data.total_record_per_page
              : 10;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    ExportForm() {
      this.IDs = [];
      this.datas = this.$store.state.tms.tms.selected;
      this.datas.forEach((element) => {
        this.IDs.push(element.id);
      });
      if (this.IDs.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }
      var fileTitle = "Transport Delivery Plan";
      // this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/export-transport-delivery-plan", {
          responseType: "arraybuffer",
          params: {
            id_selected: this.IDs,
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
    cancelPI() {
      console.log("reason",this.selectReason)
      let DOArray =[]
      DOArray.push(this.PIData.delivery_order_id)

      this.$http
        .post("/api/wms/v1/simple-outbound-processing/cancel-so-release", {
          delivery_order_id: DOArray,
          reason_id: this.selectReason.id,
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Cancel PI Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
            this.OpenModal(false,null);
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    saveCancelPI(){
      if(this.selectReason==null){
        this.OpenModal(false,null);
        //spend time 1 second for showing notification
      
          this.$vs.notify({
            title: "Error",
            text: "Please select Reason",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        return true;
      }
      // this.$vs.dialog({
      //       type: "confirm",
      //       color: "danger",
      //       title: `Confirm`,
      //       text: "Are you sure to Cancel this PI?",
      //       accept:() => {
      //         this.cancelPI();
      //       },
      //     });
      this.cancelPI();
    },
    getCity() {
      this.$http.get("/api/wms/v1/transport-code/city").then((resp) => {
        if (resp.code == 200) {
          this.optionalCity = resp.data.records;
        }
      });
    },
    getDistrict() {
      this.$http
        .get("api/wms/v1/master/district", {
          params: {
            city_id: this.selectCity.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionalDistrict = resp.data.records.map((el) => {
              return {
                id: el.id,
                name: el.name,
              };
            });
          }
        });
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    onClickBtnFilter() {
      this.getData();
    },
    onClickBtnReset() {
      this.$store.dispatch("tms/tms/updateDate", {
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
      });
      this.$store.commit("tms/tms/setDistrict", []);
      this.$store.commit("tms/tms/setCity", []);
      this.getData();
    },
    saveDate(val) {
      this.$store.commit("tms/tms/setDate", val);
      console.log("date", val);
    },
    saveDistrict(val) {
      console.log("district", this.selectDistrict);
      this.districts = [];
      this.$store.commit("tms/tms/setDistrict", val);
      this.selectDistrict.forEach((element) => {
        this.districts.push(element.name);
      });
      // console.log("district", val);
    },
    saveCity(val) {
      this.$store.commit("tms/tms/setCity", val);
      this.getDistrict();
      // console.log("city", val);
    },
    getReason(){
      let params = {
      length: 999,
      order: "id",
      sort: "asc",
      reason_groups_name: ["Cancel PI"],
    };
      this.$http
      .get("/api/wms/v1/master/reason", {
          params: params,
        }).then((resp) => {
        if (resp.code == 200) {
          this.optionReason = resp.data.records;
        }
      });
      console.log("ooooooo", this.optionReason)
    },
    OpenModal(val, data) {
      
      
      if (val){
        console.log("data..",data)
      this.PICode=null;
      this.PIData=null;
      this.selectReason=null;

      this.showfirstModal = val;
      this.PIData = data;
      this.PICode = data.delivery_order_code;
      this.getReason();
      }
    },
  },
  watch: {
    "table.data": {
      handler(newData) {
        // Update selectedItems based on new data and Vuex store state
        this.selectedItems = newData.map((item) =>
          this.$store.state.tms.tms.selected.some(
            (selectedItem) => selectedItem.id === item.id
          )
        );
        // Update selectAll state if all or none are selected
        this.selectAll = this.selectedItems.every(Boolean);
      },
      immediate: true,
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    selectedData: {
      get() {
        return this.$store.state.tms.tms.selected;
      },
      set(val) {
        console.log(">>>>selected", val);
        this.$store.commit("tms/tms/setSelected", val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.modal-footer {
  padding: 15px 0px 0px 0px;
  border-top: 1px solid #e5e5e5;
  margin-left: -14px;
  margin-right: -14px;
}
</style>
<vs-popup v-model="showModal" title="Modal Title" width="500">